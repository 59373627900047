<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="success" @click="addnew">新增调查表</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="300" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="success" size="mini">详情</el-button>
							<el-button @click="userdetail(scope.row)" v-if="scope.row.type==1" type="primary" size="mini">填写情况</el-button>
							<el-button @click="editerow(scope.row)" type="warning" size="mini">编辑</el-button>
							<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>
		
		<mydetail ref="mydetail"></mydetail>
		<user_list ref="user_list"></user_list>
	</div>
</template>

<script>
	import mydetail from '../component/detail.vue';
	import user_list from '../component/user_list.vue';
	export default {
		components:{
			mydetail,
			user_list
		},
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '调查表名称',
						width: ''
					},
					{
						prop: 'numbers',
						name: '调查人数',
						width: '130'
					},
					{
						prop: 'time_status',
						name: '状态',
						type: 'wenjuan_type',
						width: '130'
					},
					{
						prop: 'kstime',
						name: '开始时间',
						width: '180'
					},
					{
						prop: 'jstime',
						name: '结束时间',
						width: '180'
					},
					{
						prop: 'addtime',
						name: '创建时间',
						width: '180'
					}
				],
				tableData: []
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			userdetail(row){
				this.$refs.user_list.id = row.id
				this.$refs.user_list.show = true
				this.$refs.user_list.getlist()
			},
			showdetail(row){
				this.$post({
					url: '/api/questionnaire/indextm',
					params: {
						questionnaire_id:row.id
					}
				}).then((res) => {
					res.list.forEach((item)=>{
						item.da = ''
						if(item.type==3){
							item.da = []
						}
						item.xx.forEach((item2)=>{
							if(item2.hasInput){
								item2.input_value = ''
							}
						})
					})
					row.timu_list = res.list
					
					Object.keys(row).forEach(key => {
					    this.$set(this.$refs.mydetail.detail, key, row[key]);
					});
					this.$refs.mydetail.dialogVisible = true
					console.log(this.$refs.mydetail.detail)
				})
				
				
			},
			deleterow(row){
				this.$confirm('请确认是否删除该调查表?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/questionnaire/del',
						params: {
							ids:row.id
						}
					}).then((res) => {
						this.$message.success('删除成功');
						this.gettable()
					})
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/questionnaire/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					const currentTime = new Date();
					
					res.list.forEach((item) => {
						item.numbers = item.ry.split(',').length						
						
						const kstime = new Date(item.kstime);
						const jstime = new Date(item.jstime);
						if(item.type==2){
							item.time_status = 5; // 暂存
							return
						}
						if (currentTime < kstime) {
							item.time_status = 1; // 未开始
						} else if (currentTime >= kstime && currentTime <= jstime) {
							item.time_status = 2; // 进行中
						} else {
							item.time_status = 3; // 已结束
						}
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			editerow(row){
				this.$router.push({path:'/system/question_inves_table_edit',query:{id:row.id}})
			},
			addnew() {
				this.$router.push('/system/question_inves_table_add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'list.scss';
</style>